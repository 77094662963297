import React, { useState } from 'react';

import styled from 'styled-components';
// atoms
import Headline from '../atoms/headline';
import Button from '../atoms/button';
import IconButton from '../atoms/iconbutton';
import Tr from '../atoms/tr';
import Th from '../atoms/th';


import Header from '../organisms/header';

import plusIcon from '../../assets/plus.png';
import plusIcon2x from '../../assets/plus@2x.png';

import trashIcon from '../../assets/trash.png';
import trashIcon2x from '../../assets/trash@2x.png';

import penIcon from '../../assets/pen.png';
import penIcon2x from '../../assets/pen@2x.png';



function Breaktemplate({
    schicht
}) {

    async function deleteBreak(id) {
        var r = window.confirm("Möchtest du diesen Datensatz löschen?");
        if (r == true) {
            const response = await fetch(`${process.env.REACT_APP_API}/break/${id}`, {
                method: 'DELETE',
                mode: 'cors',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer'
            });
            if(response){
                window.location.reload(false);
            }
        }
    }
    

    return (
        <div className="container">
            <Header />

            <div className="row">
                <div className="col s6">
                    <Headline>Schichten / Pausen</Headline>
                </div>
                <div className="col s6">
                    <Button icon={plusIcon} size="medium" className="right" onClick={()=> {
                        window.location.href = '/break/create';
                    }}>Anlegen</Button>
                </div>
            </div>
            <div className="row">
                <div className="col s12">
                    <table>
                        <thead>
                            <Th>Typ</Th>
                            <Th>Schicht</Th>
                            <Th>Woche</Th>
                            <Th>Beschreibung</Th>
                            <Th>Uhrzeit von</Th>
                            <Th>Uhrzeit bis</Th>
                            <Th>Datum</Th>
                            <Th>Aktionen</Th>
                        </thead>
                        <tbody>
                            {schicht && schicht.map(schicht => {
                                const options = { year: 'numeric', month: 'numeric', day: 'numeric' };

                                return (<Tr>
                                    <td>{schicht.breakType === "1" ? 'Schicht' : ''}{schicht.breakType === "2" ? 'Pause' : ''}{schicht.breakType === "3" ? 'Sonderschicht' : ''}{schicht.breakType === "4" ? 'Sonderpause' : ''}</td>
                                    <td>{schicht.shift}</td>
                                    <td>{schicht.week === 'ODD' ? 'Ungerade Wochen' :''}{schicht.week === 'EVEN' ? 'Gerade Wochen' :''}{schicht.week === 'ALWAYS' ? 'Jede Woche' :''}</td>
                                    <td>{schicht.breakDescription}</td>
                                    <td>{schicht.breakTimeStart}</td>
                                    <td>{schicht.breakTimeEnd}</td>
                                    <td>{schicht.dateFix ? (new Date(schicht.dateFix)).toLocaleDateString('de-DE', options) : ''}</td>
                                    <td>
                                        <IconButton icon={trashIcon} onClick={() => {
                                            deleteBreak(schicht.id);
                                        }} ></IconButton> 
                                        <IconButton icon={penIcon} href={`/break/edit/${schicht.id}`}></IconButton>
                                    </td>
                                </Tr>)
                            })}
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    )
}


export default Breaktemplate;