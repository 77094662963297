import React from 'react';
import useFetch from '../../hooks/useFetch';

import Editmastertemplate from '../templates/Editmastertemplate';

function Createmasterpage() {
  return (
    <>
      <Editmastertemplate
        master={{
          id: null,
          productgroup: '',
          productdescription: '',
          tour: '',
          content: 0,
          priority: 0,
          unloadingGate: '',
          destination: '',
          constructionTime: 0,
          source: undefined,
          postfix: '',
          forceCorrectOrderOnBand: false
        }}
      />
    </>
  );
}

export default Createmasterpage;
