

import React from 'react';

import styled from 'styled-components';

const ThStyled = styled.th`
    color: #8F8F98;
    border: none;
    font-size: 16px;
    padding: 15px 20px;
    text-transform: uppercase;
`;

function Th(props) {

    return (
        <ThStyled {...props}>
            {props.children}
        </ThStyled>
    )
}

export default Th;