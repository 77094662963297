

import React from 'react';

import styled from 'styled-components';

const IconButtonStyled = styled.a`
    background: url(${props => props.icon});
    background-repeat: no-repeat;
    background-size: contain;
    width: 16px;
    height: 16px;
    display: inline-block;
    margin-left: 10px;
    cursor: pointer;
`;
function IconButton(props) {

    return (
        <IconButtonStyled {...props}>
            {props.children}
        </IconButtonStyled>
    )
}

export default IconButton;