import React from 'react';

import useFetch from '../../hooks/useFetch';

import Beltspeedtemplate from '../templates/Beltspeedtemplate';



function Beltspeedpage() {

    const accessToken = localStorage.getItem('accessToken');

    const beltspeed = useFetch(`${process.env.REACT_APP_API}/beltspeed`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer'
    });

    return beltspeed && (
        <>
            <Beltspeedtemplate beltspeed={beltspeed} />
        </>
    )
}


export default Beltspeedpage;