import React from 'react';

import styled from 'styled-components';



const LogoStyled = styled.img`
    height: 50px;
    width: auto;
    margin-right: 15px;
`;

function Logo(props) {

    return (
    <LogoStyled {...props} /> 
    )
}

export default Logo;