import React from 'react';
import useFetch from '../../hooks/useFetch';

import Editusertemplate from '../templates/Editusertemplate';


function Createuserpage() {

    return (
        <>
            <Editusertemplate user={
                {
                    id: null,
                    name: '',
                    tour: '',
                    linefeeder: true,
                    beladen: true
                }
            } />
        </>
    )
}


export default Createuserpage;