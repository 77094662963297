import React from 'react';
import useFetch from '../../hooks/useFetch';

import Editusertemplate from '../templates/Editusertemplate';


function Edituserpage(props) {

    const userId = props.match.params.id || null;

    const accessToken = localStorage.getItem('accessToken');

    const user = useFetch(`${process.env.REACT_APP_API}/user/${userId}`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer'
    });

    return user && (
        <>
            <Editusertemplate user={user || {
                id: null,
                name: null,
                tour: null,
                role: null,
                linefeeder: true,
                beladen: true
            } } />
        </>
    )
}


export default Edituserpage;