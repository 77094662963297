import React, { useEffect, useState } from 'react';

import { Redirect } from 'react-router-dom';


import arrowIcon from '../../assets/arrow.png';
import arrowIcon2x from '../../assets/arrow@2x.png';


// atoms
import Button from '../atoms/button';
import Label from '../atoms/label';
import Input from '../atoms/input';
import Headline from '../atoms/headline';
import UserIcon from '../atoms/usericon';
import PasswordIcon from '../atoms/passwordicon';
import Form from '../atoms/form';
import Labeltext from '../atoms/labeltext';
import Textbutton from '../atoms/textbutton';
import Select from '../atoms/select';

// organisms
import Header from '../organisms/header';


function Editusertemplate({ user }) {
    const [state, setState] = useState({
        id: user.id,
        name: user.name,
        tour: user.tour,
        role: user.role,
        password: '',
        repeatpassword: '',
        message: '',
        disabled: true,
        linefeeder: user.linefeeder,
        beladen: user.beladen
    })

    function handleChange(e) {
        setState({
            ...state,
            [e.target.name]: e.target.value
        });
    }

    function handleChecked(e) {
        setState({
            ...state,
            [e.target.name]: e.target.checked
        });
    }

    function handleKeyup(event) {
        if (event.keyCode === 13) {
            handleSubmit();
        }
    }

    async function handleSubmit(e) {
        e.target.disabled = true;
        let params = {
            name: state.name,
            tour: state.tour,
            role: state.role,
            linefeeder: state.linefeeder,
            beladen: state.beladen
        }

        if (state.password && state.password === state.repeatpassword) {
            params = {
                ...params,
                password: state.password
            }
        }

        const accessToken = localStorage.getItem('accessToken');
        const response = await fetch(`${process.env.REACT_APP_API}/user${state.id ? `/${state.id}` : ''}`, {
            method: state.id ? 'PATCH' : 'POST',
            mode: 'cors',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(params)
        });

        const responseObj = await response.json();
        if(responseObj) {
            window.location.href = '/user';
        }
        
    }

    useEffect( () => {
        if(validateInput() === false) {
            setState({
                ...state,
                disabled: false
            })
        } else {
            setState({
                ...state,
                disabled: true
            })
        }
    }, [state.name, state.password, state.role, state.tour, state.repeatpassword, state.linefeeder, state.beladen]);

    function validateInput() {
        if (!state.name.length) {
            return true
        }

        if (state.id) {
            // for update
        } else {
            debugger;
            // for create
            if(!state.password.length) {
                return true;
            }
            if(state.password !== state.repeatpassword) {
                return true;
            }
        }

        if (!state.linefeeder && !state.beladen) {
            return true;
        }

        return false;
    }

    const { message } = state;

    return (
        <div className="container">
            <Header />

            <div className="row">
                <div className="col s12 m6 l6 xl6">
                    <Headline>Benutzer bearbeiten</Headline>

                    <Form>
                        <Labeltext>Benutzername</Labeltext>

                        <Label>
                            <UserIcon />
                            <Input type="text" onChange={handleChange} onKeyUp={handleKeyup} value={state.name} name="name" placeholder="Benutzername" />
                        </Label>

                        <Label>
                            <UserIcon />
                            <Input type="text" onChange={handleChange} onKeyUp={handleKeyup} name="tour" value={state.tour} placeholder="Tour01" />
                        </Label>

                        <div className="row">
                            <div className="col s12">
                                <Labeltext>Rolle</Labeltext>
                                <span className="col s8" style={{padding: '0 0.75rem 0 0'}}>
                                    <Select options={[
                                                        { name: "Wähle Benutzerrolle" },
                                                        { value: "ADMIN", name: "Admin" },
                                                        { value: "USER", name: "User" }
                                                    ]} 
                                            value={state.role} 
                                            name="role" 
                                            onChange={handleChange} />
                                </span>
                                
                                <span className="col s4" style={{padding: 0}}>
                                    <label style={{ display: "flex", alignItems: "center", marginBottom: "0.75rem" }}>
                                        <Input type="checkbox" 
                                               checked={state.linefeeder} 
                                               name="linefeeder" 
                                               onChange={handleChecked} 
                                               style={{ display: "none" }} />
                                        <span></span>
                                        Linefeeder
                                    </label>
                                                    
                                    <label style={{ display: "flex", alignItems: "center" }}>
                                        <Input type="checkbox" 
                                               checked={state.beladen} 
                                               name="beladen" 
                                               onChange={handleChecked} 
                                               style={{ display: "none" }} />
                                        <span></span>
                                        Beladen
                                    </label>
                                </span>
                            </div>
                        </div>

                        <Labeltext>Neues Passwort anlegen</Labeltext>

                        <Label>
                            <PasswordIcon />
                            <Input type="password" onChange={handleChange} onKeyUp={handleKeyup} name="password" placeholder="Passwort" />
                        </Label>

                        <Label>
                            <PasswordIcon />
                            <Input type="password" onChange={handleChange} onKeyUp={handleKeyup} name="repeatpassword" placeholder="Passwort wiederholen" />
                        </Label>

                        {message ? message : null}

                        {state.id ? (<Textbutton marginTop={'60px'}>Benutzer löschen</Textbutton>) : null}

                        <Button disabled={state.disabled} 
                                marginTop={'60px'} 
                                size="medium" 
                                icon={arrowIcon} 
                                className="right" 
                                onClick={handleSubmit}>
                            Speichern
                        </Button>
                    </Form>
                </div>
            </div>
        </div>
    );
}


export default Editusertemplate;