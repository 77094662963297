import React from 'react';
import Logintemplate from '../templates/Logintemplate';


function Loginpage() {

    return (
        <>
            <Logintemplate />
        </>
    )
}


export default Loginpage;