import React from 'react';
import useFetch from '../../hooks/useFetch';

import Editbreaktemplate from '../templates/Editbreaktemplate';


function Editbreakpage(props) {

    const breakId = props.match.params.id || null;

    const accessToken = localStorage.getItem('accessToken');

    const schicht = useFetch(`${process.env.REACT_APP_API}/break/${breakId}`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer'
    });

    return schicht && (
        <>
            <Editbreaktemplate schicht={schicht} />
        </>
    )
}


export default Editbreakpage;