import React from 'react';

import styled from 'styled-components';

const InputStyled = styled.input`
  width: 100% !important;
  border: none !important;
  background-color: #fafafa !important;
  border-radius: 10px !important;
  padding-left: 57px;
  height: 66px;
  line-height: 64px;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 15px;
  ${(props) =>
    props.icon === false
      ? `
        padding-left: 20px;
    `
      : ''}
`;

function Input(props) {
  return <InputStyled {...props}>{props.children}</InputStyled>;
}

export default Input;
