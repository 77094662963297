import React from 'react';
import {
    
    Redirect
} from 'react-router-dom'
function Logoutpage() {
   
    localStorage.removeItem('accessToken');
    localStorage.removeItem('userType');
    
    return (
        <>
             <Redirect to="/" />
        </>
    )
}


export default Logoutpage;