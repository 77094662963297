import React, { useState } from 'react';

import arrowIcon from '../../assets/arrow.png';

// atoms
import Button from '../atoms/button';
import Label from '../atoms/label';
import Input from '../atoms/input';
import Headline from '../atoms/headline';
import Form from '../atoms/form';
import Labeltext from '../atoms/labeltext';
import Textbutton from '../atoms/textbutton';
import Select from '../atoms/select';
import LabelForCheckbox from '../atoms/LabelForCheckbox';
// organisms
import Header from '../organisms/header';

function Editusertemplate({ master }) {
  const [state, setState] = useState({
    id: master.id,
    productgroup: master.productgroup,
    productdescription: master.productdescription,
    tour: master.tour,
    content: master.content,
    priority: master.priority,
    unloadingGate: master.unloadingGate,
    destination: master.destination,
    constructionTime: master.constructionTime,
    source: master.source,
    unloadingNeeded: master.unloadingNeeded,
    disabled: false,
    createMovementRecord: false,
    tolerance: master.tolerance,
    postfix: master.postfix,
    checkToleranceDialog: master.checkToleranceDialog,
    forceCorrectOrderOnBand: master.forceCorrectOrderOnBand
  });
  function handleDisabled() {
    let disabled = false;

    if (
      (state.source !== 'LFS' && state.source !== 'MANUAL') ||
      state.productgroup === '' ||
      state.destination === '' ||
      state.productdescription === '' ||
      state.tour === '' ||
      state.content === '' ||
      state.unloadingGate === '' ||
      state.constructionTime === ''
    ) {
      disabled = true;
    }

    return disabled;
  }

  async function handleChange(e) {
    // postfixLengthCheck(e);
    await setState({
      ...state,
      [e.target.name]: e.target.value
    });
  }

  // function postfixLengthCheck(e) {
  //   if (e.target.name === 'postfix' && e.target.value.length < 3) {
  //     window.alert('Postfix must be no longer than 2 characters');
  //   }
  // }

  async function handleNumbericChange(e) {
    await setState({
      ...state,
      [e.target.name]: e.target.valueAsNumber
    });
  }

  async function handleCheckedChange(e) {
    await setState({
      ...state,
      [e.target.name]: e.target.checked
    });
  }

  function handleKeyup(event) {
    if (event.keyCode === 13) {
      handleSubmit();
    }
  }

  async function handleSubmit(e) {
    if (handleDisabled()) {
      setState({
        ...state,
        disabled: true
      });
    } else {
      e.target.disabled = true;
      var params = {
        productgroup: state.productgroup,
        productdescription: state.productdescription,
        tour: state.tour,
        content: state.content,
        priority: state.priority,
        unloadingGate: state.unloadingGate,
        destination: state.destination,
        constructionTime: state.constructionTime,
        source: state.source,
        unloadingNeeded: state.unloadingNeeded,
        createMovementRecord: state.createMovementRecord,
        tolerance: state.tolerance,
        checkToleranceDialog: state.checkToleranceDialog,
        postfix: state.postfix,
        forceCorrectOrderOnBand: state.forceCorrectOrderOnBand
      };

      const accessToken = localStorage.getItem('accessToken');
      console.log(params);
      const response = await fetch(
        `${process.env.REACT_APP_API}/master${state.id ? `/${state.id}` : ''}`,
        {
          method: state.id ? 'PATCH' : 'POST',
          mode: 'cors',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + accessToken
          },
          redirect: 'follow',
          referrerPolicy: 'no-referrer',
          body: JSON.stringify(params)
        }
      );

      const responseObj = await response.json();
      if (responseObj) {
        window.location.href = '/master';
      }
    }
  }

  const { message } = state;
  return (
    <div className="container">
      <Header />

      <div className="row">
        <div className="col s12 m6 l12 xl12">
          <Headline>Stammdaten bearbeiten</Headline>
        </div>
        <div className="col s12">
          <Form>
            <div className="row">
              <div className="col s3">
                <Labeltext>Produktgruppe</Labeltext>
              </div>
              <div className="col s3">
                <Labeltext>Produktbeschreibung</Labeltext>
              </div>
              <div className="col s3">
                <Labeltext>Postfix</Labeltext>
              </div>
            </div>
            <div className="row">
              <div className="col s12 m2">
                <Label>
                  <Input
                    icon={false}
                    type="text"
                    onChange={handleChange}
                    onKeyUp={handleKeyup}
                    value={state.productgroup}
                    name="productgroup"
                    placeholder="ITA"
                  />
                </Label>
              </div>
              <div className="col s12 m4">
                <Label>
                  <Input
                    icon={false}
                    type="text"
                    onChange={handleChange}
                    onKeyUp={handleKeyup}
                    value={state.productdescription}
                    name="productdescription"
                    placeholder="I Tafel"
                  />
                </Label>
              </div>
              <div className="col s12 m2">
                <Label>
                  <Input
                    icon={false}
                    type="text"
                    onChange={handleChange}
                    onKeyUp={handleKeyup}
                    value={state.postfix}
                    name="postfix"
                    placeholder="postfix"
                    maxLength="2"
                  />
                </Label>
              </div>
            </div>
            <div className="row">
              <div className="col s3"></div>
            </div>
            <div className="row">
              <div className="col s12 m2">
                <Labeltext>Tour</Labeltext>
                <Label>
                  <Input
                    icon={false}
                    type="text"
                    onChange={handleChange}
                    onKeyUp={handleKeyup}
                    value={state.tour}
                    name="tour"
                    placeholder="TOUR04"
                  />
                </Label>
              </div>
              <div className="col s12 m2">
                <Labeltext>Inhalt</Labeltext>
                <Label>
                  <Input
                    icon={false}
                    type="number"
                    onChange={handleChange}
                    onKeyUp={handleKeyup}
                    value={state.content}
                    name="content"
                    placeholder="4"
                  />
                </Label>
              </div>
              <div className="col s12 m2">
                <Labeltext>Priorität</Labeltext>
                <Label>
                  <Input
                    icon={false}
                    type="number"
                    onChange={handleChange}
                    onKeyUp={handleKeyup}
                    value={state.priority}
                    name="priority"
                    placeholder="priority"
                  />
                </Label>
              </div>
            </div>
            <div className="row">
              <div className="col s12 m2">
                <Labeltext>Entladetor</Labeltext>
                <Label>
                  <Input
                    icon={false}
                    type="text"
                    onChange={handleChange}
                    onKeyUp={handleKeyup}
                    value={state.unloadingGate}
                    name="unloadingGate"
                    placeholder="SÜD01"
                  />
                </Label>
              </div>
              <div className="col s12 m2">
                <Labeltext>Endziel</Labeltext>
                <Label>
                  <Input
                    icon={false}
                    type="text"
                    onChange={handleChange}
                    onKeyUp={handleKeyup}
                    value={state.destination}
                    name="destination"
                    placeholder="HI63"
                  />
                </Label>
              </div>
              <div className="col s12 m3">
                <Labeltext>
                  Verbauzeit <small>(hh:mm)</small>
                </Labeltext>
                <Label>
                  <Input
                    icon={false}
                    type="time"
                    onChange={handleChange}
                    onKeyUp={handleKeyup}
                    value={state.constructionTime}
                    lang="en-150"
                    name="constructionTime"
                    placeholder="--:--"
                  />
                </Label>
              </div>
            </div>
            <div className="row">
              <div className="col s12 m6">
                <Labeltext>Datenquelle</Labeltext>
                <Select
                  options={[
                    { name: 'Wähle Datenquelle' },
                    { value: 'LFS', name: 'LFS' },
                    { value: 'MANUAL', name: 'Manuell' }
                  ]}
                  value={state.source}
                  name="source"
                  onChange={handleChange}
                ></Select>
              </div>
              {state.source === 'MANUAL' ? (
                <div className="col s12 m6">
                  <LabelForCheckbox>
                    <Labeltext>
                      Bewegungsdatensatz erstellen{' '}
                      <small>(wenn nicht bereits einer existiert)</small>
                    </Labeltext>
                    <Input
                      type="checkbox"
                      value={state.createMovementRecord}
                      name="createMovementRecord"
                      onChange={handleChange}
                    />
                    <span></span>
                  </LabelForCheckbox>
                </div>
              ) : (
                ''
              )}
            </div>
            <div className="row">
              <div className="col s12 m6">
                <LabelForCheckbox>
                  <Labeltext>Entladen notwendig?</Labeltext>
                  <Input
                    type="checkbox"
                    checked={state.unloadingNeeded}
                    name="unloadingNeeded"
                    onChange={handleCheckedChange}
                  />
                  <span></span>
                </LabelForCheckbox>
              </div>
            </div>
            <div className="row">
              <div className="col s12 m6">
                <Labeltext>Tolerance</Labeltext>
                <Label>
                  <Input
                    icon={false}
                    type="number"
                    min="0"
                    onChange={handleNumbericChange}
                    onKeyUp={handleKeyup}
                    value={state.tolerance}
                    name="tolerance"
                    placeholder="Tolerance"
                  />
                </Label>
              </div>
              {state.tolerance !== 0 ? (
                <div className="col s12 m6">
                  <LabelForCheckbox>
                    <Labeltext>Tolerance check dialog</Labeltext>
                    <Input
                      type="checkbox"
                      checked={state.checkToleranceDialog}
                      value={state.checkToleranceDialog}
                      name="checkToleranceDialog"
                      onChange={handleCheckedChange}
                    />
                    <span></span>
                  </LabelForCheckbox>
                </div>
              ) : (
                ''
              )}
            </div>
            <div className="row">
              <div className="col s12 m6">
                <LabelForCheckbox>
                  <Labeltext>
                    Korrekte Reihenfolge bei abladen forcieren?
                  </Labeltext>
                  <Input
                    type="checkbox"
                    checked={state.forceCorrectOrderOnBand}
                    name="forceCorrectOrderOnBand"
                    onChange={handleCheckedChange}
                  />
                  <span></span>
                </LabelForCheckbox>
              </div>
            </div>
            <div className="row">
              <div className="col s12 m6">
                {message ? message : null}
                {state.id ? (
                  <Textbutton marginTop={'60px'}>Stammdate löschen</Textbutton>
                ) : null}
                <Button
                  disabled={handleDisabled()}
                  marginTop={'60px'}
                  size="medium"
                  icon={arrowIcon}
                  className="right"
                  onClick={handleSubmit}
                >
                  Speichern
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default Editusertemplate;
