import React, { useState } from 'react';

import Headline from '../atoms/headline';
import Button from '../atoms/button';
import IconButton from '../atoms/iconbutton';
import Input from '../atoms/input';
import Tr from '../atoms/tr';
import Th from '../atoms/th';
import CalibratePopup from '../organisms/calibratePopup';

import Header from '../organisms/header';

import plusIcon from '../../assets/plus.png';
import trashIcon from '../../assets/trash.png';
import penIcon from '../../assets/pen.png';
import watchIcon from '../../assets/watch-icon.png';

function Mastertemplate({ masters }) {
  const DEFAULT_STATE = {
    openPopup: false,
    calibrateId: null,
    takt: null,
    message: null
  };

  const [state, setState] = useState(DEFAULT_STATE);

  async function deleteMaster(id) {
    var r = window.confirm('Möchtest du diesen Datensatz löschen?');
    if (r == true) {
      const response = await fetch(
        `${process.env.REACT_APP_API}/master/${id}`,
        {
          method: 'DELETE',
          mode: 'cors',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('accessToken')
          },
          redirect: 'follow',
          referrerPolicy: 'no-referrer'
        }
      );
      if (response) {
        window.location.reload(false);
      }
    }
  }

  async function adjustTakt() {
    const response = await fetch(
      `${process.env.REACT_APP_API}/master/calibrate/${state.calibrateId}`,
      {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('accessToken')
        },
        body: JSON.stringify({
          takt: state.takt
        }),
        redirect: 'follow',
        referrerPolicy: 'no-referrer'
      }
    );
    if (response) {
      const status = await response.json();
      setState({
        ...state,
        message: status.status
          ? `Erfolgreich abgeschlossen`
          : `Kalibrierung konnte nicht durchgeführt werden`
      });
      setTimeout(handleClosePopup, 1500);
    }
  }

  const userType = localStorage.getItem('userType');

  const { openPopup, calibrateId, takt, message } = state;

  const handleOpenPopup = (event) => {
    event.preventDefault();
    setState({
      message: null,
      openPopup: true,
      calibrateId: event.target.dataset.id,
      takt: null
    });
  };

  const handleChange = (event) => {
    switch (event.target.type) {
      case 'number':
        setState({
          ...state,
          [event.target.name]: parseInt(event.target.value)
        });
        break;
      case 'text':
        setState({
          ...state,
          [event.target.name]: event.target.value
        });
        break;
    }
  };

  const handleClosePopup = (event) => {
    setState(DEFAULT_STATE);
  };

  return (
    <div className="container">
      <Header />
      {openPopup && (
        <CalibratePopup
          takt={takt}
          adjustTakt={adjustTakt}
          handleClosePopup={handleClosePopup}
          handleTaktChange={handleChange}
          message={message}
        />
      )}
      <div className="row">
        <div className="col s6">
          <Headline>Stammdaten</Headline>
        </div>
        <div className="col s6">
          <Button
            icon={plusIcon}
            size="medium"
            className="right"
            onClick={() => {
              window.location.href = '/master/create';
            }}
          >
            Anlegen
          </Button>
        </div>
      </div>
      <div className="row">
        <div className="col s12">
          <table>
            <thead>
              <Th>Produkt</Th>
              <Th>Beschreibung</Th>
              <Th>Tour</Th>
              <Th>Ziel</Th>
              <Th>Tor</Th>
              <Th>Inhalt</Th>
              <Th>Verb. Zeit (hh:mm)</Th>
              {userType === 'ADMIN' && <Th>Aktionen</Th>}
            </thead>
            <tbody>
              {masters &&
                masters.map((master) => {
                  return (
                    <Tr>
                      <td>{master.productgroup}</td>
                      <td>{master.productdescription}</td>
                      <td>{master.tour}</td>
                      <td>{master.destination}</td>
                      <td>{master.unloadingGate}</td>
                      <td>{master.content}</td>
                      <td>{master.constructionTime}</td>
                      <td>
                        <IconButton
                          icon={trashIcon}
                          onClick={() => {
                            deleteMaster(master.id);
                          }}
                        ></IconButton>
                        <IconButton
                          icon={penIcon}
                          href={`/master/edit/${master.id}`}
                        ></IconButton>
                        <IconButton
                          href="#"
                          icon={watchIcon}
                          data-id={master.id}
                          onClick={handleOpenPopup}
                        ></IconButton>
                      </td>
                    </Tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Mastertemplate;
