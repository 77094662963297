import React from 'react';

import styled from 'styled-components';

const WeekdayWrapperStyled = styled.div`
    position: relative;
    display: flex;
    input[type="checkbox"]:checked + span {
        &:before {
            content: '';
            border-right-color: #ee7203 !important;
            border-bottom-color: #ee7203 !important;
        }
    }
`;

function WeekdayWrapper(props) {

    return (
    <WeekdayWrapperStyled {...props}> 
        {props.children}
    </WeekdayWrapperStyled>
    )
}

export default WeekdayWrapper;

