import { useState, useEffect } from 'react';

const useFetch = (url, options = {}) => {
    const [data, setData] = useState(null);
    // empty array as second argument equivalent to componentDidMount
    useEffect(() => {
        async function fetchData(url, options) {
            try {
                const response = await fetch(url, options);
                
                const json = await response.json();
                if(json && json.statusCode === 401) {
                    localStorage.removeItem('accessToken');
                    window.location.href = '/';
                }
                setData(json);
            } catch (e) {
                console.error(e);
            }
        }
        fetchData(url, options);
    }, [url]);

    return data;
};

export default useFetch;