

import React from 'react';

import styled from 'styled-components';

const TrStyled = styled.tr`
    &:nth-child(odd) {
        background: #FAFAFA;
        border-radius: 5px;
    }
    color: #EE7203;
    border: none;
    font-weight: 500;
    font-size: 20px;
    td {
        padding: 15px 20px;
    }
`;
function Tr(props) {

    return (
        <TrStyled {...props}>
            {props.children}
        </TrStyled>
    )
}

export default Tr;