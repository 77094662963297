import React from 'react';

import styled from 'styled-components';


import passwordIcon from '../../assets/password-icon.png';
import passwordIcon2x from '../../assets/password-icon@2x.png';




const PasswordIconStyled = styled.span`
    position: absolute;
    background: url(${passwordIcon});
    background-repeat: no-repeat;
    width: 22px;
    height: 22px;
    left: 20px;
    top: 20px;
`;

function PasswordIcon(props) {

    return (
    <PasswordIconStyled {...props} /> 
    )
}

export default PasswordIcon;