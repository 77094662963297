import React from 'react';

import styled from 'styled-components';

const LabeltextStyled = styled.div`
    color: #8F8F98;
    text-transform: uppercase;
    margin-bottom: 10px;
`;

function Labeltext(props) {

    return (
    <LabeltextStyled {...props}> 
        {props.children}
    </LabeltextStyled>
    )
}

export default Labeltext;