import React from 'react';

import useFetch from '../../hooks/useFetch';

import Usertemplate from '../templates/Usertemplate';



function Userpage() {

    const accessToken = localStorage.getItem('accessToken');

    const users = useFetch(`${process.env.REACT_APP_API}/user`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer'
    });

    return (
        <>
            <Usertemplate users={users} />
        </>
    )
}


export default Userpage;