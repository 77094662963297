import React from 'react';
import { BrowserRouter, Route} from 'react-router-dom';
import Loginpage from './components/pages/Loginpage';
import Logoutpage from './components/pages/Logoutpage';
import Overviewpage from './components/pages/Overviewpage';
import Userpage from './components/pages/Userpage';
import Edituserpage from './components/pages/Edituserpage';
import Createuserpage from './components/pages/Createuserpage';
import Createmasterpage from './components/pages/Createmasterpage';
import Editmasterpage from './components/pages/Editmasterpage';
import Masterpage from './components/pages/Masterpage';
import Createbreakpage from './components/pages/Createbreakpage';
import Editbreakpage from './components/pages/Editbreakpage';
import Breakpage from './components/pages/Breakpage';
import Beltspeedpage from './components/pages/Beltspeedpage';

import Privateroute from './components/helpers/Privateroute';

import './assets/materialize.css';

function App() {
  return (
    <>
      <BrowserRouter>
        <Route path="/" exact component={Loginpage} />
        <Route path="/login" exact component={Loginpage} />
        <Route path="/logout" exact component={Logoutpage} />
        <Privateroute path="/overview" exact component={Overviewpage} />
        <Privateroute path="/master" exact component={Masterpage} />
        <Privateroute path="/user" exact component={Userpage} />
        <Privateroute path="/break" exact component={Breakpage} />
        <Privateroute path="/user/edit/:id" exact component={Edituserpage} />
        <Privateroute path="/user/create" exact component={Createuserpage} />
        <Privateroute path="/master/edit/:id" exact component={Editmasterpage} />
        <Privateroute path="/master/create" exact component={Createmasterpage} />
        <Privateroute path="/break/edit/:id" exact component={Editbreakpage} />
        <Privateroute path="/break/create" exact component={Createbreakpage} />
        <Privateroute path="/belt" exact component={Beltspeedpage} />
      </BrowserRouter>
    </>
  );
}

export default App;
