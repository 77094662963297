import React from 'react';
import useFetch from '../../hooks/useFetch';

import Editbreaktemplate from '../templates/Editbreaktemplate';


function Createbreakpage() {

    return (
        <>
            <Editbreaktemplate schicht={
                {
                    id: null,
                    breakType: undefined,
                    breakDescription: '',
                    breakTimeStart: 0,
                    breakTimeEnd: 0,
                    dateFix: (new Date()).toISOString(),
                    monday: true,
                    tuesday: true,
                    wednesday: true, 
                    thursday: true, 
                    friday: true, 
                    saturday: false, 
                    sunday: false, 
                    wholeDay: false,
                    week: 'ALWAYS'
                }
            } />
        </>
    )
}


export default Createbreakpage;