import React, { useEffect, useState } from 'react';

import {
    Redirect
} from 'react-router-dom'


import arrowIcon from '../../assets/arrow.png';
import arrowIcon2x from '../../assets/arrow@2x.png';


// atoms
import Button from '../atoms/button';
import Label from '../atoms/label';
import Input from '../atoms/input';
import Headline from '../atoms/headline';
import UserIcon from '../atoms/usericon';
import PasswordIcon from '../atoms/passwordicon';
import Form from '../atoms/form';
import Labeltext from '../atoms/labeltext';
import Textbutton from '../atoms/textbutton';
import Select from '../atoms/select';
import WeekdayWrapper from '../atoms/WeekdayWrapper';
// organisms
import Header from '../organisms/header';


function Editbreaktemplate({ schicht }) {
    console.log(schicht);
    const [state, setState] = useState({
        id: schicht.id,
        breakType: schicht.breakType,
        breakDescription: schicht.breakDescription,
        breakTimeStart: schicht.breakTimeStart,
        breakTimeEnd: schicht.breakTimeEnd,
        dateFix: schicht.dateFix,
        disabled: true,
        shift: schicht.shift,
        week: schicht.week,
        monday: schicht.monday,
        tuesday: schicht.tuesday,
        wednesday: schicht.wednesday,
        thursday: schicht.thursday,
        friday: schicht.friday,
        saturday: schicht.saturday,
        sunday: schicht.sunday,
        wholeDay: schicht.wholeDay
    })

    function handleChecked(e) {
        console.log(e.target.checked);
        setState({
            ...state,
            [e.target.name]: e.target.checked
        });
    }

    function handleChange(e) {
        setState({
            ...state,
            [e.target.name]: e.target.value
        });
    }

    function handleKeyup(event) {
        if (event.keyCode === 13) {
            handleSubmit();
        }
    }

    async function handleSubmit(e) {
        e.target.disabled = true;
       
        var params = {
            breakType: state.breakType,
            breakDescription: state.breakDescription,
            breakTimeStart: ( (state.breakType === "1" || state.breakType === "2") || ((state.breakType === "3" || state.breakType === "4") && !state.wholeDay)) ? state.breakTimeStart : null,
            breakTimeEnd: ( (state.breakType === "1" || state.breakType === "2") || ((state.breakType === "3" || state.breakType === "4") && !state.wholeDay)) ? state.breakTimeEnd : null,
            dateFix: (state.breakType === "3" || state.breakType === "4") ? state.dateFix : null,
            shift: (state.breakType === "1" || state.breakType === "2") ? state.shift : null,
            week: (state.breakType === "1" || state.breakType === "2") ? state.week : null,
            monday:  (state.breakType === "1" || state.breakType === "2") ? JSON.parse(state.monday) : false,
            tuesday: (state.breakType === "1" || state.breakType === "2") ? JSON.parse(state.tuesday) : false,
            wednesday: (state.breakType === "1" || state.breakType === "2") ? JSON.parse(state.wednesday) : false,
            thursday: (state.breakType === "1" || state.breakType === "2") ? JSON.parse(state.thursday) : false,
            friday: (state.breakType === "1" || state.breakType === "2") ? JSON.parse(state.friday) : false,
            saturday: (state.breakType === "1" || state.breakType === "2") ? JSON.parse(state.saturday) : false,
            sunday: (state.breakType === "1" || state.breakType === "2") ? JSON.parse(state.sunday) : false,
            wholeDay: (state.breakType === "3" || state.breakType === "4") ? JSON.parse(state.wholeDay) : null
        }



        const accessToken = localStorage.getItem('accessToken');
        const response = await fetch(`${process.env.REACT_APP_API}/break${state.id ? `/${state.id}` : ''}`, {
            method: state.id ? 'PATCH' : 'POST',
            mode: 'cors',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(params)
        });

        const responseObj = await response.json();
        if (responseObj) {
            window.location.href = '/break';
        }
    }

    useEffect(() => {
        if (validateInput() === false) {
            setState({
                ...state,
                disabled: false
            })
        } else {
            setState({
                ...state,
                disabled: true
            })
        }
    }, [state.breakType, state.breakDescription, state.breakTimeStart, state.breakTimeEnd, state.wholeDay]);
    function validateInput() {
        
        if (state.breakType == undefined) {
            return true;
        }
        if (!state.breakDescription.length) {
            return true
        }
        if ((state.breakType === "3" || state.breakType === "4") && !state.wholeDay && !state.breakTimeStart.length) {
            return true
        }
        if ((state.breakType === 1 || state.breakType === 2) && !state.breakTimeStart.length) {
            return true
        }
        if ((state.breakType === 3 || state.breakType === 4) && !state.wholeDay && !state.breakTimeEnd.length) {
            return true
        }
        if ((state.breakType === 1 || state.breakType === 2) && !state.breakTimeEnd.length) {
            return true
        }
        return false;
    }

    console.log(state);
    const { message } = state;

    return (
        <div className="container">
            <Header />

            <div className="row">
                <div className="col s12 m6 l6 xl12">
                    <Headline>Schichten / Pausen bearbeiten</Headline>
                </div>
                <div className="col s12">
                    <Form>
                        <div className="row">
                            <div className="col s12 m8">
                                <Labeltext>Typ</Labeltext>
                                <Select options={[
                                    { name: "Wähle Typ" },
                                    { value: "1", name: "Schichten" },
                                    { value: "2", name: "Pausen" },
                                    { value: "3", name: "Sonderschichten" },
                                    { value: "4", name: "Spezielle Pausen/Urlaubstage" }                                    
                                ]} value={state.breakType} name="breakType" onChange={handleChange} required ></Select>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col s12 m8">
                                <Labeltext>Beschreibung</Labeltext>
                                <Label>
                                    <Input icon={false} type="text" onChange={handleChange} onKeyUp={handleKeyup} value={state.breakDescription} name="breakDescription" placeholder="Beschreibung" />
                                </Label>
                            </div>

                        </div>

                        {state.breakType !== "3" && state.breakType !== "4" ? (
                            <>
                                <div className="row">
                                    <div className="col s12 m4">
                                        <Labeltext>Schicht</Labeltext>
                                        <Select options={[
                                            { name: "Wähle Schicht" },
                                            { value: "Frühschicht", name: "Frühschicht" },
                                            { value: "Spätschicht", name: "Spätschicht" },
                                            { value: "Nachtschicht", name: "Nachtschicht" }
                                        ]} value={state.shift} name="shift" onChange={handleChange} ></Select>

                                    </div>
                                    <div className="col s12 m4">
                                        <Labeltext>Woche</Labeltext>
                                        <Select options={[
                                            { value: "ALWAYS", name: "Jede Woche" },
                                            { value: "ODD", name: "Nur ungerade Wochen" },
                                            { value: "EVEN", name: "Nur gerade Wochen" }
                                        ]} value={state.week} name="week" onChange={handleChange} ></Select>

                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col s12 m4">
                                        <Labeltext>Wochentage</Labeltext>
                                        <WeekdayWrapper>
                                            <label>
                                                Mo:
                                        <br /><Input type="checkbox" value={state.monday} checked={state.monday} name="monday" onChange={handleChecked} />
                                                <span></span>
                                            </label>
                                            <label>
                                                Di:
                                        <br /><Input type="checkbox" value={state.tuesday} checked={state.tuesday} name="tuesday" onChange={handleChecked} />
                                                <span></span>
                                            </label>
                                            <label>
                                                Mi:
                                        <br /><Input type="checkbox" value={state.wednesday} checked={state.wednesday} name="wednesday" onChange={handleChecked} />
                                                <span></span>
                                            </label>
                                            <label>
                                                Do:
                                        <br /><Input type="checkbox" value={state.thursday} checked={state.thursday} name="thursday" onChange={handleChecked} />
                                                <span></span>
                                            </label>

                                            <label>
                                                Fr:
                                        <br /><Input type="checkbox" value={state.friday} checked={state.friday} name="friday" onChange={handleChecked} />
                                                <span></span>
                                            </label>
                                            <label>
                                                Sa:
                                        <br /><Input type="checkbox" value={state.saturday} checked={state.saturday} name="saturday" onChange={handleChecked} />
                                                <span></span>
                                            </label>
                                            <label>
                                                So:
                                        <br /><Input type="checkbox" value={state.sunday} checked={state.sunday} name="sunday" onChange={handleChecked} />
                                                <span></span>
                                            </label>
                                        </WeekdayWrapper>

                                    </div>
                                </div>
                            </>
                        ) :
                            (
                                <div className="row">
                                    <div className="col s12 m3">
                                        <Labeltext>Spezielles Datum</Labeltext>
                                        <Label>
                                            <Input icon={false} type="date" onChange={handleChange} onKeyUp={handleKeyup} value={state.dateFix.substring(0, 10)} name="dateFix" />
                                        </Label>
                                    </div>
                                    <div className="col s12 m3">
                                    <Labeltext>Ganztägig:</Labeltext>
                                        <WeekdayWrapper>
                                        <Label>
                                            <label>
                                            <Input type="checkbox" value={state.wholeDay} checked={state.wholeDay} name="wholeDay" onClick={handleChecked} />
                                            <span></span>
                                            </label>
                                        </Label>
                                        </WeekdayWrapper>
                                        
                                    </div>
                                </div>)}
                        {!state.wholeDay ? (<div className="row">
                            <div className="col s12 m2">
                                <Labeltext>Uhrzeit Von</Labeltext>
                                <Label>
                                    <Input icon={false} type="time" onChange={handleChange} onKeyUp={handleKeyup} value={state.breakTimeStart} name="breakTimeStart" placeholder="--:--" />
                                </Label>
                            </div>
                            <div className="col s12 m2">
                                <Labeltext>Uhrzeit Bis</Labeltext>
                                <Label>
                                    <Input icon={false} type="time" onChange={handleChange} onKeyUp={handleKeyup} value={state.breakTimeEnd} name="breakTimeEnd" placeholder="--:--" />
                                </Label>
                            </div>
                        </div>) : ''}

                        <div className="row">
                            <div className="col s12 m6">
                                {message ? message : null}
                                {state.id ? (<Textbutton marginTop={'60px'}>Stammdate löschen</Textbutton>) : null}
                                <Button disabled={state.disabled} marginTop={'60px'} size="medium" icon={arrowIcon} className="right" onClick={handleSubmit}>Speichern</Button>
                            </div>
                        </div>
                    </Form>

                </div>

            </div>
        </div>
    )
}


export default Editbreaktemplate;