import React from 'react';
import Overviewtemplate from '../templates/Overviewtemplate';
import { SpinnerStyled } from '../atoms/spinner';
import useFetch from '../../hooks/useFetch';

function Overviewpage() {
  const accessToken = localStorage.getItem('accessToken');

  const leitstand = useFetch(`${process.env.REACT_APP_API}/master/leitstand`, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + accessToken
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer'
  });

  const beltstatus = useFetch(`${process.env.REACT_APP_API}/stop`, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + accessToken
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer'
  });

  const productGroupsData = useFetch(
    `${process.env.REACT_APP_API}/movement/product-groups/all`,
    {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer'
    }
  );

  return leitstand && beltstatus && productGroupsData ? (
    <>
      <Overviewtemplate
        leitstand={leitstand}
        beltstatus={beltstatus}
        productGroupsData={productGroupsData}
      />
    </>
  ) : (
    <SpinnerStyled></SpinnerStyled>
  );
}

export default Overviewpage;
