import React from 'react';

import styled from 'styled-components';

const HeadlineStyled = styled.h1`
    margin-top:0;
    font-size: 45px;
    font-weight: bold; 
    margin-bottom: 35px;
`;

function Headline(props) {

    return (
    <HeadlineStyled {...props}> 
        {props.children}
    </HeadlineStyled>
    )
}

export default Headline;