import React from 'react';
import styled from "styled-components";
import Input from "../atoms/input";

const PopupStyled = styled.div`
    background: white;
    width: 500px;
    height: 300px;
    border-radius: 10px;
    box-shadow: 0px 0px  2px lightgrey;
    position: fixed;
    top: 50%;
    left: 50%;
    z-index:10;
    padding: 15px;
    transform: translate(-50%, -50%);
    input {
        margin-top: 50px;
        height: 40px;
        width: 180px !important; 
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        border: 2px solid #EE7203 !important;
        &:focus-visible {
            outline: none !important;
        }  
    } 
`;

const CloseButton = styled.a`
  cursor: pointer;
    padding: 10px 20px;
    position: absolute;
    bottom: 15px;
    right: 125px;
`;

const ConfirmButton = styled.button`
  cursor: pointer;
    border: none;
    border-radius: 10px;
    background: #EE7203;
    color: white;
    
    padding: 10px 20px;
    position: absolute;
    bottom: 15px;
    right: 15px;
`;

export default function CalibratePopup({takt, handleClosePopup, adjustTakt, message, handleTaktChange}) {
  return (
    <PopupStyled>
      <h5>Neukalibrierung von Produktgruppe</h5>
      {message ? (<h4>{message}</h4>) : (<><p>Bitte geben Sie den aktuellen Takt an</p>
        <Input icon={false} type="number" value={takt} onChange={handleTaktChange} placeholder="Aktueller Takt" name="takt" />
        <CloseButton onClick={handleClosePopup}>Abbrechen</CloseButton>
        <ConfirmButton onClick={adjustTakt}>Speichern</ConfirmButton></>)}
    </PopupStyled>
  )
}
