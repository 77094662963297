import React from 'react';

import styled from 'styled-components';

export const SpinnerStyled = styled.div`
  display: inline-block;
  width: 100vh;
  height: 100vw;

  &:after {
    content: ' ';
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    border: 6px solid #ee7203;
    border-color: #ee7203 transparent #ee7203 transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
