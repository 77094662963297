import React from 'react';

import useFetch from '../../hooks/useFetch';
import { SpinnerStyled } from '../atoms/spinner';
import Mastertemplate from '../templates/Mastertemplate';

function Masterpage() {
  const accessToken = localStorage.getItem('accessToken');

  const masters = useFetch(`${process.env.REACT_APP_API}/master`, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + accessToken
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer'
  });

  return (
    <>
      {' '}
      {masters ? (
        <Mastertemplate masters={masters} />
      ) : (
        <SpinnerStyled></SpinnerStyled>
      )}
    </>
  );
}

export default Masterpage;
