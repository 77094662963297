import React from 'react';

import Headline from '../atoms/headline';
import Button from '../atoms/button';
import IconButton from '../atoms/iconbutton';
import Tr from '../atoms/tr';
import Th from '../atoms/th';


import Header from '../organisms/header';

import plusIcon from '../../assets/plus.png';

import trashIcon from '../../assets/trash.png';

import penIcon from '../../assets/pen.png';



function Usertemplate({
    users
}) {

    async function deleteUser(id) {
        var r = window.confirm("Möchtest du diesen Datensatz löschen?");
        if (r === true) {
            const response = await fetch(`${process.env.REACT_APP_API}/user/${id}`, {
                method: 'DELETE',
                mode: 'cors',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer'
            });
            if(response){
                window.location.reload(false);
            }
        }
    }
    

    return (
        <div className="container">
            <Header />

            <div className="row">
                <div className="col s6">
                    <Headline>Benutzer</Headline>
                </div>
                <div className="col s6">
                    <Button icon={plusIcon} size="medium" className="right" onClick={()=> {
                        window.location.href = '/user/create';
                    }}>Anlegen</Button>
                </div>
            </div>
            <div className="row">
                <div className="col s12">
                    <table>
                        <thead>
                            <Th>Benutzer</Th>
                            <Th>Tour</Th>
                            <Th>Rolle</Th>
                            <Th>Aktionen</Th>
                        </thead>
                        <tbody>
                            {users && users.map(user => {
                                return (<Tr>
                                    <td>{user.name}</td>
                                    <td>{user.tour}</td>
                                    <td>{user.role}</td>
                                    <td>
                                        <IconButton icon={trashIcon} onClick={() => {
                                            deleteUser(user.id);
                                        }} ></IconButton> 
                                        <IconButton icon={penIcon} href={`/user/edit/${user.id}`}></IconButton>
                                    </td>
                                </Tr>)
                            })}
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    )
}


export default Usertemplate;