import React, { useState } from 'react';

import {
    Redirect
} from 'react-router-dom'


import arrowIcon from '../../assets/arrow.png';
import arrowIcon2x from '../../assets/arrow@2x.png';


// atoms
import Button from '../atoms/button';
import Label from '../atoms/label';
import Input from '../atoms/input';
import Headline from '../atoms/headline';
import UserIcon from '../atoms/usericon';
import PasswordIcon from '../atoms/passwordicon';
import Form from '../atoms/form';

// organisms
import Header from '../organisms/header';


function Logintemplate() {

    const [state, setState] = useState({
        username: null,
        password: null,
        message: '',
        accessToken: localStorage.getItem('accessToken')
    })

    function handleChange(e) {
        setState({
            ...state,
            [e.target.name]: e.target.value
        });
    }

    function handleKeyup(event) {
        if(event.keyCode === 13) {
            handleSubmit();
        }
    }

    async function handleSubmit(e) {

        const response = await fetch(`${process.env.REACT_APP_API}/user/signin`, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({
                name: state.username,
                password: state.password
            })
        });

        const responseObj = await response.json();
        if (responseObj.accessToken) {
            if (responseObj.userType === 'ADMIN') {
                localStorage.setItem('accessToken', responseObj.accessToken);
                localStorage.setItem('userType', responseObj.userType);
                setState({
                    ...state,
                    accessToken: responseObj.accessToken
                })
            }
            else {
                setState({
                    ...state,
                    message: 'Dieser Benutzer ist nicht autorisiert'
                })
            }
          
        } else {
            setState({
                ...state,
                message: responseObj.message
            })
        }
    }

    const { message, accessToken } = state;

    return (
        <div className="container">
            <Header />
            
            <div className="row">
                <div className="col s12 m6">
                    <Headline>Login</Headline>
                    <Form>
                        <Label>
                            <UserIcon />
                            <Input type="text" onChange={handleChange} onKeyUp={handleKeyup} name="username" placeholder="Benutzername" />
                        </Label>
                        <Label>
                            <PasswordIcon />
                            <Input type="password" onChange={handleChange} onKeyUp={handleKeyup} name="password" placeholder="Passwort" />
                        </Label>
                        { message ? message : null }
                        <Button icon={arrowIcon} className="right" onClick={handleSubmit}>Anmelden</Button>
                    </Form>
                </div>

            </div>
            {accessToken && (<><Redirect to='/overview' /></>)}
        </div>
    )
}


export default Logintemplate;