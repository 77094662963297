
import React from 'react';


import styled from 'styled-components';
import dropdownIcon from '../../assets/dropdown.png';
import dropdownIcon2x from '../../assets/dropdown@2x.png';


const SelectWrapper = styled.div`
    display: inline-block;
    width: 100%;
    position: relative;
    cursor: pointer;
    &:before { 
        position: absolute;
        content: '';
        width: 16px;
        height: 9px;
        background-image: url(${dropdownIcon});
        background-repeat: no-repeat;
        display: block;
        top: 30px;
        right: 20px;
    }
`;

const SelectStyled = styled.select`
    background: #FAFAFA;
    border: none;
    height: 66px;
    border-radius: 10px;
    padding: 20px;
    font-size: 20px;
    font-weight: 500;
    -webkit-appearance: none; 
    appearance: none 
    cursor: pointer;
    ${ props => props.value === undefined && ` color:  #8F8F98;` }
    
`;

function Select(props) {
    return (
    <SelectWrapper>
        <SelectStyled {...props}> 
            {props.options.map( (option, index) => {
                return (<option key={index} disabled={option.value === undefined && props.value !== undefined ? true : false} value={option.value}>{option.name}</option>)
            } )}
        </SelectStyled>
    </SelectWrapper>
    )
}

export default Select;