import React from 'react';

// atoms
import Nav from '../atoms/nav';
import Logo from '../atoms/logo';
import Name from '../atoms/name';

// image files
import logo from '../../assets/logo.webp';


function Header(props) {

    return (
        <div className="row">
            <div className="col s12">
                <Nav>
                    <Logo src={logo} /> <Name>Linefeeder</Name>
                </Nav>
            </div>


        </div>
    )
}

export default Header;