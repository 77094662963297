import React from 'react';
import useFetch from '../../hooks/useFetch';

import Editmastertemplate from '../templates/Editmastertemplate';


function Edituserpage(props) {

    const masterId = props.match.params.id || null;

    const accessToken = localStorage.getItem('accessToken');

    const master = useFetch(`${process.env.REACT_APP_API}/master/${masterId}`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer'
    });

    return master && (
        <>
            <Editmastertemplate master={master} />
        </>
    )
}


export default Edituserpage;