import React from 'react';

import styled from 'styled-components';

const LabelStyled = styled.label`
    position: relative;
    input[type="checkbox"] + span {
        margin-top: 20px;
    }
    input[type="checkbox"]:not(:checked) + span {
        &:before {
            content: ''
        }
    }
    input[type="checkbox"]:checked + span {
        &:before {
            content: '';
            border-right-color: #ee7203 !important;
            border-bottom-color: #ee7203 !important;
        }
    }
`;

function LabelForCheckbox(props) {

    return (
    <LabelStyled {...props}> 
        {props.children}
    </LabelStyled>
    )
}

export default LabelForCheckbox;