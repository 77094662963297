import React, { useState, useEffect, useRef } from 'react';

import styled from 'styled-components';
import watchIcon from '../../assets/watch-icon.png';
import IconButton from '../atoms/iconbutton';

import Tr from '../atoms/tr';
import Th from '../atoms/th';

import Headline from '../atoms/headline';
import Button from '../atoms/button';
import CalibratePopup from '../organisms/calibratePopup';

import Header from '../organisms/header';

const SpanBlack = styled.span`
  color: black;
`;
const TableWrapper = styled.div`
  th,
  td {
    font-size: 14px !important;
    padding: 10px;
  }
`;
const TableHeader = styled.button`
  background: rgba(238, 114, 3, 0.05);
  &:focus {
    background: rgba(238, 114, 3, 0.05);
  }
  color: #1b1c26;
  border-radius: 5px;
  font-size: 20px;
  padding: 18px 0 12px 20px;
  margin-bottom: 20px;
  margin-top: 20px;
  width: 100%;
  text-align: left;
  border: none;
  position: relative;

  &:after {
    content: '+';
    position: absolute;
    right: 20px;
    top: 14px;
    color: #dc5a00;
    ${(props) =>
      props.open
        ? `
            transform: rotate(45deg);
        `
        : ''}
  }
`;

const CursorPointerWrapper = styled.span`
  position: relative;
  cursor: default;
`;

const Tooltip = styled.div`
  position: absolute;
  background: #fff;
  cursor: default;

  .tooltip-table {
    overflow: hidden;
    border-radius: 5px;

    th,
    td {
      padding: 10px 20px;
    }
  }

  .tooltip-header {
    display: block;
    background: #ee7203;
    color: #fff;
  }

  .tooltip-table-body {
    display: block;
    max-height: 200px;
    overflow-y: auto;

    tr {
      display: block;
      border-bottom: none;
      background: #fafafa;
    }

    tr:nth-child(odd) {
      background: #e6e6e6;
    }

    td {
      &:first-child {
        width: 150px;
      }

      &:not(:first-child) {
        width: 110px;
      }
    }

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background: #c8c8c8;
      border-radius: 25px;
    }

    &::-webkit-scrollbar-track {
      background-color: #fcfcfc;
    }
  }
`;

function Overviewtemplate({ leitstand, beltstatus, productGroupsData }) {
  const DEFAULT_STATE = {
    openPopup: false,
    calibrateId: null,
    takt: null,
    message: null
  };
  const [state, setState] = useState({
    leitstand: leitstand[0],
    userPerTour: leitstand[1],
    timeframe: null,
    allTours: leitstand[0]
      .filter((item) => item.cnt > 0)
      .map((item) => item.tour)
      .filter((value, index, self) => {
        return self.indexOf(value) === index;
      }),
    selectedTours: leitstand[0]
      .filter((item) => item.cnt > 0)
      .map((item) => item.tour)
      .filter((value, index, self) => {
        return self.indexOf(value) === index;
      }),
    toursLastUpdaters: {}
  });
  const [isLoading, setIsLoading] = useState(false);
  const [popupState, setPopupState] = useState(DEFAULT_STATE);
  const [showTooltip, setShowTooltip] = useState(false);
  const [selectedTooltipData, setSelectedTooltipData] = useState(null);
  const [tooltipOffsets, setTooltipOffsets] = useState({
    top: null,
    left: null
  });

  const fetchInterval = useRef(null);

  const zeroPad = (num, places) => String(num).padStart(places, '0');

  async function getLeitstand() {
    const accessToken = localStorage.getItem('accessToken');
    const response = await fetch(
      `${process.env.REACT_APP_API}/master/leitstand`,
      {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + accessToken
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer'
      }
    );
    const leitstand = await response.json();
    return leitstand;
  }

  useEffect(() => {
    async function fillStateFromLeitstand() {
      const leitstand = await getLeitstand();
      setState({
        leitstand: leitstand[0],
        userPerTour: leitstand[1],
        timeframe: null,
        allTours: leitstand[0]
          .filter((item) => item.cnt > 0)
          .map((item) => item.tour)
          .filter((value, index, self) => {
            return self.indexOf(value) === index;
          }),
        selectedTours: leitstand[0]
          .filter((item) => item.cnt > 0)
          .map((item) => item.tour)
          .filter((value, index, self) => {
            return self.indexOf(value) === index;
          }),
        toursLastUpdaters: {}
      });
    }

    if (isLoading) {
      fillStateFromLeitstand();
      setIsLoading(false);
    }
  }, [isLoading]);

  useEffect(() => {
    if (fetchInterval.current !== null) {
      clearInterval(fetchInterval.current);
    }
    fetchInterval.current = setInterval(() => {
      setIsLoading(true);
    }, 1000 * 60 * 3);
    return () => {
      clearInterval(fetchInterval.current);
    };
  }, []);

  async function changeBeltStatus(status) {
    const accessToken = localStorage.getItem('accessToken');
    await fetch(`${process.env.REACT_APP_API}/stop`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        status
      })
    });
  }

  async function stopBelt() {
    await changeBeltStatus('STOPPED');
    window.location.reload();
  }

  async function startBelt() {
    await changeBeltStatus('CONTINUED');
    window.location.reload();
  }

  const toggleTour = (tour) => {
    var selectedTours = state.selectedTours;
    if (state.selectedTours.includes(tour)) {
      selectedTours = state.selectedTours.filter(
        (selectedTour) => selectedTour !== tour
      );
    } else {
      selectedTours.push(tour);
    }

    setState({
      ...state,
      selectedTours
    });
  };

  const handleClosePopup = (event) => {
    setPopupState(DEFAULT_STATE);
    setIsLoading(true);
  };

  const handleTaktChange = (event) => {
    switch (event.target.type) {
      case 'number':
        setPopupState({
          ...popupState,
          [event.target.name]: parseInt(event.target.value)
        });
        break;
      case 'text':
        setPopupState({
          ...popupState,
          [event.target.name]: event.target.value
        });
        break;
      default:
    }
  };

  async function adjustTakt() {
    const response = await fetch(
      `${process.env.REACT_APP_API}/master/calibrate/${popupState.calibrateId}`,
      {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('accessToken')
        },
        body: JSON.stringify({
          takt: popupState.takt
        }),
        redirect: 'follow',
        referrerPolicy: 'no-referrer'
      }
    );
    if (response) {
      const status = await response.json();
      setPopupState({
        ...popupState,
        message: status.status
          ? `Erfolgreich abgeschlossen`
          : `Kalibrierung konnte nicht durchgeführt werden`
      });
      setTimeout(handleClosePopup, 1000);
    }
  }

  const handleOpenPopup = (event) => {
    event.preventDefault();
    setPopupState({
      message: null,
      openPopup: true,
      calibrateId: event.target.dataset.id,
      takt: null
    });
  };

  const renderTooltip = () => (
    <Tooltip
      style={tooltipOffsets}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => {
        setShowTooltip(false);
        setSelectedTooltipData(null);
      }}
    >
      <table className="tooltip-table">
        <thead className="tooltip-header">
          <th>SENDUNGSNR.</th>
          <th>TAKT VON</th>
          <th>TAKT BIS</th>
        </thead>

        <tbody className="tooltip-table-body">
          {selectedTooltipData &&
            selectedTooltipData.map((el, index) => (
              <tr key={index} className="tooltip-row">
                <td>{el.delivery}</td>
                <td>{zeroPad(el.sequenceStart, 4)}</td>
                <td>{zeroPad(el.sequenceEnd, 4)}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </Tooltip>
  );

  const { openPopup, takt, message } = popupState;

  const onTooltipEventHandle = (event) => {
    const productGroupId = event.target.getAttribute('data-group');
    const selectedColumn = event.target.getAttribute('data-column');

    const findCorrectProductGroup =
      productGroupsData &&
      productGroupsData.find(
        (group) => group.productGroupId === +productGroupId
      );

    if (
      findCorrectProductGroup &&
      findCorrectProductGroup[selectedColumn] === undefined
    ) {
      return;
    }
    const groupsToShow = findCorrectProductGroup[selectedColumn];

    if (groupsToShow && groupsToShow.length > 0) {
      setSelectedTooltipData([...groupsToShow]);
      setShowTooltip(true);
    }

    if (event.clientY > 800) {
      const numberOfRows = Number(event.target.innerHTML);

      setTooltipOffsets({
        top:
          numberOfRows <= 5
            ? event.target.offsetTop - numberOfRows * 40
            : event.target.offsetTop - 200,
        left: event.target.offsetLeft - 50
      });
    } else {
      setTooltipOffsets({
        top: event.target.offsetTop,
        left: event.target.offsetLeft - 50
      });
    }
  };

  return (
    <>
      <div className="container">
        <Header />

        {openPopup && (
          <CalibratePopup
            takt={takt}
            message={message}
            handleOpenPopup={handleOpenPopup}
            handleTaktChange={handleTaktChange}
            handleClosePopup={handleClosePopup}
            adjustTakt={adjustTakt}
          />
        )}

        <div className="row">
          <div className="col s12 m6">
            <Headline>Leitstand</Headline>
          </div>

          <div className="col s12 m3 right">
            <Button
              size="medium"
              className="right"
              disabled={
                beltstatus.status === 'STOPPED' && beltstatus.type === 'MANUAL'
              }
              color={'#C95959'}
              onClick={stopBelt}
            >
              Band Anhalten
            </Button>
          </div>

          <div className="col s12 m3 right">
            <Button
              size="medium"
              className="right"
              disabled={
                !(
                  beltstatus.status === 'STOPPED' &&
                  beltstatus.type === 'MANUAL'
                )
              }
              color={'green'}
              onClick={startBelt}
            >
              Band Starten
            </Button>
          </div>
        </div>

        <div className="row">
          <div className="col s12">
            {state.allTours && state.allTours.length
              ? state.allTours.map((currentTour, index) => (
                  <TableWrapper key={index}>
                    <TableHeader
                      open={state.selectedTours.includes(currentTour)}
                      onClick={() => toggleTour(currentTour)}
                    >
                      {`${currentTour} - ${
                        state.userPerTour.find(
                          (uPt) => uPt.tour === currentTour
                        )?.name || ''
                      }`}
                    </TableHeader>

                    {state.selectedTours.includes(currentTour) ? (
                      <table>
                        <thead>
                          <Th>Produktgruppe</Th>
                          <Th>LFS verl.</Th>
                          <Th>Takt</Th>
                          <Th>Werk bel.</Th>
                          <Th>Takt</Th>
                          <Th>Am Band</Th>
                          <Th>Takt</Th>
                          <Th></Th>
                        </thead>

                        <tbody>
                          {state.leitstand && state.leitstand.length
                            ? state.leitstand.map((leitstandItem, index) =>
                                currentTour === leitstandItem.tour &&
                                leitstandItem.cnt > 0 ? (
                                  <Tr key={index}>
                                    <td>{leitstandItem.productgroup}</td>

                                    <td>
                                      <CursorPointerWrapper
                                        data-group={leitstandItem.masterId}
                                        data-column="LFSVERLADEN"
                                        onMouseEnter={(e) =>
                                          onTooltipEventHandle(e)
                                        }
                                      >
                                        {leitstandItem.LFSVERLADEN}
                                      </CursorPointerWrapper>
                                    </td>

                                    <td>
                                      <SpanBlack>
                                        {(leitstandItem.LFS_VERL_SEQ_START &&
                                          zeroPad(
                                            leitstandItem.LFS_VERL_SEQ_START,
                                            4
                                          )) ||
                                          '0'}{' '}
                                        -{' '}
                                        {(leitstandItem.LFS_VERL_SEQ_END &&
                                          zeroPad(
                                            leitstandItem.LFS_VERL_SEQ_END,
                                            4
                                          )) ||
                                          '0'}
                                      </SpanBlack>
                                    </td>

                                    <td>
                                      <CursorPointerWrapper
                                        data-group={leitstandItem.masterId}
                                        data-column="WERK_ENTLADEN"
                                        onMouseEnter={(e) =>
                                          onTooltipEventHandle(e)
                                        }
                                      >
                                        {leitstandItem.WERK_ENTLADEN}
                                      </CursorPointerWrapper>
                                    </td>

                                    <td>
                                      <SpanBlack>
                                        {(leitstandItem.WERK_ENTLADEN_SEQ_START &&
                                          zeroPad(
                                            leitstandItem.WERK_ENTLADEN_SEQ_START,
                                            4
                                          )) ||
                                          '0'}{' '}
                                        -{' '}
                                        {(leitstandItem.WERK_ENTLADEN_SEQ_END &&
                                          zeroPad(
                                            leitstandItem.WERK_ENTLADEN_SEQ_END,
                                            4
                                          )) ||
                                          '0'}
                                      </SpanBlack>
                                    </td>

                                    <td>
                                      <CursorPointerWrapper
                                        data-group={leitstandItem.masterId}
                                        data-column="AM_BAND"
                                        onMouseEnter={(e) =>
                                          onTooltipEventHandle(e)
                                        }
                                      >
                                        {leitstandItem.BAND_VERBRACHT}
                                      </CursorPointerWrapper>
                                    </td>

                                    <td>
                                      <SpanBlack>
                                        {(leitstandItem.BAND_VERBRACHT_SEQ_START &&
                                          zeroPad(
                                            leitstandItem.BAND_VERBRACHT_SEQ_START,
                                            4
                                          )) ||
                                          '0'}{' '}
                                        -{' '}
                                        {(leitstandItem.BAND_VERBRACHT_SEQ_END &&
                                          zeroPad(
                                            leitstandItem.BAND_VERBRACHT_SEQ_END,
                                            4
                                          )) ||
                                          '0'}
                                      </SpanBlack>
                                    </td>

                                    <td>
                                      <IconButton
                                        href="#"
                                        icon={watchIcon}
                                        data-id={leitstandItem.masterId}
                                        onClick={handleOpenPopup}
                                      />
                                    </td>
                                  </Tr>
                                ) : (
                                  ''
                                )
                              )
                            : ''}
                        </tbody>
                      </table>
                    ) : (
                      ''
                    )}
                  </TableWrapper>
                ))
              : ''}
          </div>
        </div>
      </div>

      {showTooltip && selectedTooltipData && renderTooltip()}
    </>
  );
}

export default Overviewtemplate;
