import React from 'react';

import styled from 'styled-components';


const TextbuttonStyled = styled.a`
    border: none;
    height: 40px;
    line-height: 40px;
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    
    
    ${props => props.marginTop && ` margin-top: ${props.marginTop}; `}

    
`;

function Textbutton(props) {

    return (
    <TextbuttonStyled {...props}> 
        {props.children}
    </TextbuttonStyled>
    )
}

export default Textbutton;