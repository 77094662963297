import React from 'react';

import useFetch from '../../hooks/useFetch';

import Breaktemplate from '../templates/Breaktemplate';



function Breakpage() {

    const accessToken = localStorage.getItem('accessToken');

    const schicht = useFetch(`${process.env.REACT_APP_API}/break`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer'
    });

    return (
        <>
            <Breaktemplate schicht={schicht} />
        </>
    )
}


export default Breakpage;