import React from 'react';

import styled from 'styled-components';

const NavStyled = styled.nav`
    background-color: initial;
    border: none;
    box-shadow: none;
    height: 50px;
    color: #8F8F98;
    font-size: 28px;
    line-height: 50px;
    display: flex;
    margin-top: 50px;
    margin-bottom: 100px;
    
`;

const Linklist = styled.ul`
    display: inline-flex;
    font-size: 15px;
    flex-grow: 1;
    justify-content: flex-end;
    

`;
const Listitem = styled.li`
    
`;
const Link = styled.a`
    color: #1B1C26;
`;

function Nav(props) {

    const isLoggedIn = localStorage.getItem('accessToken');

    return (
    <NavStyled {...props}> 
        
        {props.children}
        {isLoggedIn ?  (
            <Linklist>  
                <Listitem><Link href="/overview">Übersicht</Link></Listitem>
                <Listitem><Link href="/user">Benutzer</Link></Listitem>
                <Listitem><Link href="/break">Pausen</Link></Listitem>
                <Listitem><Link href="/master">Stammdaten</Link></Listitem>
                <Listitem><Link href="/belt">Band</Link></Listitem>
                <Listitem><Link href="/logout">Abmelden</Link></Listitem>
            </Linklist>
        )  : null}

    </NavStyled>
    )
}

export default Nav;