import React from 'react';

import styled from 'styled-components';

const FormStyled = styled.div`
   
`;

function Form(props) {

    return (
        <FormStyled {...props}>
            {props.children}
        </FormStyled>
    )
}

export default Form;