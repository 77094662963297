import React from 'react';

import styled from 'styled-components';


import userIcon from '../../assets/user-icon.png';
import userIcon2x from '../../assets/user-icon@2x.png';



const UserIconStyled = styled.span`
    position: absolute;
    background: url(${userIcon});
    background-repeat: no-repeat;
    width: 22px;
    height: 22px;
    left: 20px;
    top: 20px;
`;

function UserIcon(props) {

    return (
    <UserIconStyled {...props} /> 
    )
}

export default UserIcon;