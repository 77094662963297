import React from 'react';

import styled from 'styled-components';


const ButtonStyled = styled.button`
    background: #EE7203;
    &:focus {
        background: #EE7203;
    }
    color: white;
    border: none;
    height: 66px;
    padding: 20px 17px;
    min-width: 188px;
    cursor: pointer;
    &:after {
        content: ' ';
        background: url(${props => props.icon && props.icon});
        background-size: cover;
        width: 22px;
        height: 21px;
        background-repeat: no-repeat;
        position: absolute;
        right: 17px;
        top: 23px;
    }

    ${ props => props.size === 'medium' && `
        height: 50px;
        padding: 10px 17px;
        width: 135px;
        
        &:after {
            content: ' ';
            width: 16px;
            height: 16px;
            background-repeat: no-repeat;
            position: absolute;
            right: 17px;
            top: 17px;
            
            
        }
    `}

    ${props => props.marginTop && ` margin-top: ${props.marginTop}; `}
    border-radius: 10px;
    font-size: 20px;
    font-weight: bold;
    text-align: left;
    
    position: relative;

    ${ props => props.color && `
    background: ${props.color};
    &:focus {
        background: ${props.color};
    }
    `}

    ${props => props.disabled && `
        background: #EDEDF4;
        &:focus {
            background: #EDEDF4;
        }
        color: white;
    `}

    
    
`;

function Button(props) {

    return (
    <ButtonStyled {...props}> 
        {props.children}
    </ButtonStyled>
    )
}

export default Button;